export const getChat = async (payload) => {
  const url = process.env.REACT_APP_BASE_URL;
  const apiKey = process.env.REACT_APP_API_KEY;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': apiKey,
    },
    body: JSON.stringify(payload),
    "Cache-Control": "no-cache",
    Connection: "keep-alive",
  };

  try {
    const response = await fetch(url, requestOptions);
    if (!response.status === 200) {
      throw new Error('Network response was not ok');
    }
    return response.body
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error.message);
    throw error; 
  }
};