import React, { useState, useEffect, useRef } from "react";
import chatbotLogo from "../../assets/svgs/Quran.svg";
import resetIcon from "../../assets/svgs/history.svg";
import closeIcon from "../../assets/svgs/cancel.svg";
import ChatInput from "./ChatInput";
import { getChat } from "../../services/api";
import { useChatMessages } from "../../context/ChatProvider";
import { motion } from "framer-motion";
import CommonMarkdown from "../CommonMarkdown/CommonMarkdown";
import ScrollToBottom from "react-scroll-to-bottom";
import { throttle } from "lodash";

function Chatbot({ setChatbotVisible, array, setArray }) {
  const [loading, setLoading] = useState(false);
  const [activeStream, setActiveStream] = useState(false);
  const [input, setInput] = useState("");
  const [conversationId, setCoversationId] = useState("");
  // const { messages, clearMessages, replaceMessage } = useChatMessages();
  // const [array, setArray] = useState(messages)
  const [showChatbot, setShowChatbot] = useState(false);

  useEffect(() => {
    setShowChatbot(true);
  }, []);
  // useEffect(() => {
  //   scrollToBottom();
  // }, [array]);

  // useEffect(() => {
  //   replaceMessage(array)
  // }, [array])

  const throttledUpdateAnswers = throttle((answer) => {
    setArray((prevData) => {
      if (!prevData || prevData.length === 0) return prevData; // Safeguard against undefined and empty array
      const lastMessage = prevData[prevData.length - 1];
      if (lastMessage && lastMessage.text !== answer) {
        const updatedData = [...prevData];
        updatedData[updatedData.length - 1].text = answer;
        return updatedData;
      }
      return prevData;
    });
  }, 500);

  const readResponseBody = async (reader) => {
    setActiveStream(true)
    let buffer;
    let actualContent = "";
    while (true) {
      const { value, done } = await reader.read();
      // console.log('value', value)
      if (done) {
        setActiveStream(false)
        break;
      } else {
        if (value) {
          let chunk = new TextDecoder().decode(value, { stream: true });
          // console.log("received: ", counter, chunk);
          setLoading(false);
          buffer += chunk;

          // Split the buffer into possible JSON objects
          const parts = buffer.split('\ndata: data:').filter(Boolean);

          for (let index = 0; index < parts.length; index++) {
            const element = parts[index].trim();
            if (element) {
              try {
                let jsonStr = element.startsWith('data: data:') ? element.slice(11) : element;
                if (jsonStr.includes('data: [DONE]')) {
                  jsonStr = jsonStr.replace('data: [DONE]', '');
                }
                const parsedJson = JSON.parse(jsonStr);

                if (parsedJson.choices && parsedJson.choices[0] && parsedJson.choices[0].delta && parsedJson.choices[0].delta.content) {
                  const deltaContent = parsedJson.choices[0].delta.content;
                  actualContent += deltaContent;
                  throttledUpdateAnswers(actualContent)
                }
                // Successfully parsed, reset buffer for this part
                buffer = buffer.replace(element, '');
              } catch (error) {
                // If JSON.parse fails, it means this part is incomplete
                // Keep the buffer intact for the next chunk
                if (index === parts.length - 1) {
                  buffer = element;
                }
              }
            }
          }
        } else {
          break;
        }
      }
    }
  }

  const updateAnswers = (answer) => {
    setArray((prevData) => {
      const lastMessage = prevData[prevData.length - 1];
      if (lastMessage && lastMessage.text !== answer) {
        const updatedData = [...prevData];
        updatedData[updatedData.length - 1].text = answer;
        return updatedData;
      }
      return prevData;
    });
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newMessage = { text: input, fromUser: true };
    const newArray = [...array, newMessage, { text: "", fromUser: false }];
    setArray(newArray);
    setInput("");
    setLoading(true);
    let messagesPayload = [];

    for (let i = Math.max(0, array.length - 4); i < array.length; i++) {
      const role = array[i].fromUser ? "user" : "assistant";
      if (messagesPayload.length === 0 && role === "assistant"){
        continue
      }else{
        messagesPayload.push({ role, content: array[i].text });
      }
    }

    messagesPayload.push({ role: "user", content: input });

    const payload = {
      model: "omega",
      messages: messagesPayload,
      stream: true,
      temperature: 0.7,
    };
    const data = await getChat(payload);
    await readResponseBody(data?.getReader())
    setLoading(false);
  };

  const handleClearMessages = () => {
    setCoversationId("");
    setArray([{ text: `Welcome to Quran! 👋\n\nI'm the Quran AI Assistant, here to help you with any questions or support you need. Feel free to ask me anything, and I'll do my best to assist you. Let's make your experience smooth and enjoyable. How can I help you today?`, fromUser: false }])
  };

  return (
    <div className={`chatbot-container ${showChatbot ? "show" : ""}`}>
      <div className="chatbot-header">
        <div>
          <img
            src={chatbotLogo}
            alt="logo"
            style={{ width: "150px", height: "45px", paddingLeft: "10px" }}
          />
        </div>
        <div className="chatbot-buttons">
          <img
            src={resetIcon}
            alt="reset"
            style={{ width: "20px", height: "20px" }}
            onClick={handleClearMessages}
          />
          <img
            src={closeIcon}
            alt="close"
            style={{ width: "18px", height: "18px", paddingLeft: "15px" }}
            onClick={() => setChatbotVisible(false)}
          />
        </div>
      </div>
      <ScrollToBottom mode="bottom" className="chatbot-messages" followButtonClassName="hidebutton">
        {array
          .filter(message => message.text.trim() !== "")
          .map((message, index) => (
            <div key={index} className={message.fromUser ? "user-message-container" : "bot-message-container"}>
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.25 }}
                className={message.fromUser ? "user-message" : "bot-message"}
              >
                <CommonMarkdown content={message.text} />
              </motion.div>
            </div>
          ))}
        {loading && (
          <div className="bot-message">
            <div className="loader"></div>
            <div className="loader-text" style={{color: "#4a4a4a", fontSize: "8px"}}>Finding and Validating Information</div>
          </div>
        )}
        {/* <div ref={messagesEndRef} /> */}
      </ScrollToBottom>
      <div className="chatbot-input">
        <ChatInput
          loading={activeStream}
          input={input}
          setInput={setInput}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}

export default Chatbot;
