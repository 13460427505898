import { ImSpinner8 } from "react-icons/im";
import sendLogo from "../../assets/svgs/send.svg";

const ChatInput = ({ loading, input, setInput, handleSubmit }) => {
  //   const handleSubmit = (e) => {
  //     e.preventDefault()
  //     handleSubmit(e)
  //   };
  return (
    <form onSubmit={handleSubmit} className="form-container">
      <div className="input-container">
        <input
          type="text"
          value={input}
          required
          onChange={(e) => setInput(e.target.value)}
          placeholder="Write a question..."
        />
        <button type="submit">
          {loading ? (
            <ImSpinner8 size={30} className="spinner-icon" />
          ) : (
            <img
              src={sendLogo}
              alt="send"
              style={{ width: "40px", height: "30px" }}
            />
          )}
        </button>
      </div>
    </form>
  );
};

export default ChatInput;
