import React, { useState } from 'react';
import './App.css';
import Chatbot from './components/chatbot/Chatbot';
import chatbotButtonLogo from './assets/png/DIC.png'
function App() {
  const [chatbotVisible, setChatbotVisible] = useState(true);
  const [array, setArray] = useState([{ text: `Welcome to Quran! 👋\n\nI'm the Quran AI Assistant, here to help you with any questions or support you need. Feel free to ask me anything, and I'll do my best to assist you. Let's make your experience smooth and enjoyable. How can I help you today?`, fromUser: false }])
  const toggleChatbot = () => {
    setChatbotVisible(!chatbotVisible);
  };

  return (
    <div>
      <div className="iframe-container">
        <iframe
          src="https://quran.com/"
          width="100%"
          height="100%"
          allowFullScreen
          title="DMCC Website"
        ></iframe>
      </div>
      {chatbotVisible && <Chatbot setChatbotVisible={setChatbotVisible} array={array} setArray={setArray}/>}
      {!chatbotVisible && 
      <img className="toggle-chatbot-btn" src={chatbotButtonLogo} alt='chat' onClick={toggleChatbot}/>
      }
    </div>
  );
}

export default App;
